export const MOCK_DATA = {
  aboutData: [
    {
      id: 1,
      image: require("assets/images/document.png"),
      title: "Assessment",
      description:
        "Discover some real insights on yourself. Such as your skills, strengths, where you should invest time and effort improving to maximize your potential.",
    },
    {
      id: 2,
      image: require("assets/images/receipt-search.png"),
      title: "Resume review",
      description:
        "If your resume struggles to get on the yes pile, it could be the HR technology. Our resume architect shows you how the bots see it and flags issues to fix.",
    },
    {
      id: 3,
      image: require("assets/images/battery-full.png"),
      title: "Skill development",
      description:
        "Standing still? It may be time to enhance your job skills to land a promotion or make your next career move.",
    },
    {
      id: 4,
      image: require("assets/images/interview.png"),
      title: "Interview prep",
      description:
        "Prep for interviews by practicing typical interview questions. With our app, you’ll get real-time, AI-driven feedback so you are ready when it counts.",
    },
    {
      id: 5,
      image: require("assets/images/user-octagon.png"),
      title: "Personalize coaching",
      description:
        "Prep for interviews by practicing typical interview questions. With our app, you’ll get real-time, AI-driven feedback so you are ready when it counts.",
    },
    {
      id: 6,
      image: require("assets/images/book.png"),
      title: "eLearning",
      description:
        "Prep for interviews by practicing typical interview questions. With our app, you’ll get real-time, AI-driven feedback so you are ready when it counts.",
    },
  ],

  contactData: [
    {
      id: 1,
      title: "Assess@th.assesstm.com",
      image: require("assets/images/sms.png"),
      link: "mailto:Assess@th.assesstm.com",
    },
    {
      id: 2,
      title: "01256987463",
      image: require("assets/images/call.png"),
      link: "tel:+01256987463",
    },
    {
      id: 3,
      title: "6 october city",
      image: require("assets/images/location.png"),
      link: "https://www.google.com/maps/place/Badgewell+Software/@30.0003917,30.9738038,17z/data=!3m1!4b1!4m5!3m4!1s0x145857ebf99a093b:0xa4eeb8e51676f132!8m2!3d30.0003917!4d30.9759925",
    },
  ],

  socialLinks: [
    {
      id: 1,
      path: require("assets/images/Facebook.png"),
      link: "https://www.facebook.com/assesstm",
    },
    // {
    //   id: 2,
    //   path: require("assets/images/twitter.png"),
    //   link: "",
    // },
    {
      id: 3,
      path: require("assets/images/linked.png"),
      link: "https://www.linkedin.com/company/assess-talent-management/mycompany/",
    },
  ],
};
