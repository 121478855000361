import { ReactNode } from "react";
import { useMediaQuery } from "react-responsive";

interface Props {
  children: ReactNode;
}

const Desktop = ({ children }: Props) => {
  const isDesktop = useMediaQuery({ minWidth: 1224 });
  return <>{isDesktop ? children : null}</>;
};
const Tablet = ({ children }: Props) => {
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1224 });
  return <>{isTablet ? children : null}</>;
};
const Mobile = ({ children }: Props) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  return <>{isMobile ? children : null}</>;
};

export { Desktop, Tablet, Mobile };

// @media screen and (max-width: 991px) {
//   /* start of large tablet styles */

//   }

// @media screen and (max-width: 767px) {
// /* start of medium tablet styles */

// }

//   @media screen and (max-width: 479px) {
//   /* start of phone styles */

//   }
