import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { Footer, NavBar, NoPage } from "./components";
import ScrollToTop from "utils/scrollToTop";
import { LandingPageScreen } from "pages";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
export const App = () => {
  // document.getElementById("root").style.direction = "rtl";
  return (
    <ThemeProvider theme={darkTheme}>
      <BrowserRouter>
        <div className="App">
          <ScrollToTop />
          <NavBar />
          <Routes>
            <Route path="/" index element={<LandingPageScreen />} />
            <Route path="*" element={<NoPage />} />
            {/* <Route path="/privacy" element={<Privacy />} /> */}
          </Routes>
          <ToastContainer />
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
};
export default App;
