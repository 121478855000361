import styles from "./style.module.css";
import googlePlay from "assets/images/android.png";
import appleStore from "assets/images/apple.png";
import landingImage from "assets/images/landing.png";
import arrow from "assets/images/arrow.png";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";

interface props {
  setIsMounted: (isMount: boolean) => void;
}

export const Home = ({ setIsMounted }: props) => {
  // const isMobile = useMediaQuery({ maxWidth: 480 });
  // const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1224 });

  // useEffect(() => {
  //   setIsMounted(true);

  //   return () => {
  //     setIsMounted(false);
  //   };
  // }, []);

  return (
    <div
      onAnimationEnd={() => setIsMounted(true)}
      id="home"
      className={styles.container}>
      <div className={styles.displayFlex}>
        <div>
          <p className={styles.title}>
            Accelerate your job search & <span>advance your career</span>
          </p>
          <p className={styles.subTitle}>
            Career advice when you need it most. Through personalized career
            coaching and tools, ASSESS Progress is here to support you – one
            goal, one action, and one breakthrough at a time by putting the
            power of psychometrics in the hand of individuals.
          </p>
          <div className={styles.row}>
            <img
              // onClick={() => alert("go to google play")}
              src={googlePlay}
              className={styles.storesImageSize}
            />
            <img
              // onClick={() => alert("go to apple store")}
              src={appleStore}
              className={styles.storesImageSize}
            />
          </div>
        </div>
      </div>
      <div
        className={styles.displayFlex}
        style={{
          alignItems: "flex-end",
          // marginBottom: isMobile || isTablet ? 20 : 0,
        }}>
        <img src={landingImage} className={styles.image} />

        <img src={arrow} className={styles.arrow} />
        <div className={styles.message1}>I need help with career planning.</div>
        <div className={styles.message2}>I would to grow professionally.</div>
      </div>
    </div>
  );
};
