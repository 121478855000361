import { ISocialLink } from "models";
import { MOCK_DATA } from "utils/mockData";
import styles from "./style.module.css";

export const SocialLinks = () => {
  return (
    <div className={styles.links}>
      {MOCK_DATA.socialLinks.map((item: ISocialLink, index: number) => {
        return (
          <div className={styles.circle} key={index.toString()}>
            <a className={styles.link} href={item.link} target="_blank">
              <img
                className={item?.id == 1 ? styles.face : styles.icon}
                alt=""
                src={item.path}
              />
            </a>
          </div>
        );
      })}
    </div>
  );
};
