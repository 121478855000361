import { Variants, motion, useAnimation } from "framer-motion";
import { IAboutData } from "models";
import { MouseEventHandler, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { injectStyle } from "utils";
import { MOCK_DATA } from "utils/mockData";
import styles from "./style.module.css";
import { useMediaQuery } from "react-responsive";

const slideTopWithOpacity = `@keyframes slide-top {
  0% {
    transform: translateY(100vh);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}`;

injectStyle(slideTopWithOpacity);

export const About = () => {
  const variants: Variants = {
    visible: {
      animation: "slide-top 1s forwards",
    },
    hidden: { opacity: 0 },
  };
  const [ref, inView] = useInView();
  const control = useAnimation();
  const [isShow, setIsShow] = useState(false);
  const [selectedHover, setSelectedHover] = useState(-1);

  const scrollTo_ = () => {
    const element = document.getElementById("about");
    const pos = element.offsetTop;
    const navElement = document.getElementById("nav");
    const navbarHeight = navElement.clientHeight;
    setTimeout(() => {
      window.scrollTo(0, pos - navbarHeight);
    }, 50);
  };

  useEffect(() => {
    // console.log({ inView });

    if (inView && !isShow) {
      setIsShow(true);
      control.start("visible");
      // scrollTo_();
    }
    //  else {
    //   control.start("hidden");
    // }
  }, [control, inView]);

  //  create classNames
  // [isEnabled && 'enabled', isChecked && 'checked']
  // .filter(e => !!e)
  // .join(' ')

  const onMouseEnter = (item: number) => {
    setSelectedHover(item);
  };

  const onMouseLeave = () => {
    setSelectedHover(-1);
  };
  const isMobile = useMediaQuery({ maxWidth: 480 });

  return (
    <motion.div
      id="about"
      ref={ref}
      variants={variants}
      initial={"hidden"}
      animate={control}
      className={styles.container}>
      <div className={styles.title}>How can we help you</div>
      <div className={styles.description}>
        Lorem ipsum dolor sit amet consectetur. Cursus sed augue eget neque
        habitasse est.
      </div>
      <div className={styles.grid}>
        {MOCK_DATA.aboutData.map((item: IAboutData, index) => {
          return (
            <motion.div
              onMouseEnter={() => onMouseEnter(index)}
              onMouseLeave={onMouseLeave}
              key={item.title}
              className={styles.card}
              whileHover={{ scale: 1 }}
              whileTap={{ scale: 0.9 }}
              initial={{
                opacity: 0,
                translateY: 100,
              }}
              animate={
                isShow && {
                  opacity: 1,
                  transition: { duration: 1, delay: index * 0.2 },
                  translateY: 0,
                }
              }>
              <img className={styles.img} src={item.image} />
              <div className={styles.txt}>{item.title}</div>
              <div className={styles.desc}>{item.description}</div>
              {selectedHover == index && !isMobile && (
                <div className={styles.animatedIndicator} />
              )}
            </motion.div>
          );
        })}
      </div>
    </motion.div>
  );
};
