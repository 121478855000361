import logo from "assets/images/logo.png";
import user from "assets/images/user.png";

import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./style.module.css";
import { COLORS } from "utils";
import { Login } from "components/login";
import { ReactComponent as HomeSvg } from "assets/svgs/home.svg";
import { ReactComponent as AboutSvg } from "assets/svgs/about.svg";
import { ReactComponent as ContactSvg } from "assets/svgs/contact.svg";
import _ from "lodash";
import { useMediaQuery } from "react-responsive";

enum TABS {
  HOME = "Home",
  ABOUT = "About",
  CONTACT = "Contact",
}

export const NavBar = () => {
  const [selectTab, setSelectedTab] = useState<TABS>(TABS.HOME);
  const [isChecked, setIsChecked] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isPressedTab, setIsPressedTab] = useState(false);

  // const [navbarHeight, setNavbarHeight] = useState(normalize(112));

  // useEffect(() => {
  //   changeBackground();
  //   window.addEventListener("scroll", changeBackground);
  //   window.addEventListener("resize", resizeChanging);
  // }, []);

  // const resizeChanging = () => {
  //   setNavbarHeight(normalize(112, window.innerWidth));
  // };

  //navbar scroll changeBackground function
  const changeBackground = () => {
    // console.log(window.scrollY);
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const setActiveTab = (tab: TABS) => setSelectedTab(tab);

  const { pathname } = useLocation();
  const isHide = pathname == "/privacy";

  const renderLogo = () => (
    <img src={logo} alt="Brand Logo" title="assess" className={styles.logo} />
  );

  const scrollTo_ = (element_: TABS) => {
    // window.removeEventListener("scroll", onScroll);

    setActiveTab(element_);
    const element = document.getElementById(element_.toLowerCase());
    let pos = element.offsetTop;
    const navElement = document.getElementById("nav");
    let navbarHeight = navElement.clientHeight;
    window.scrollTo(0, pos - navbarHeight);
  };

  // const [offset, setOffset] = useState(0);

  useEffect(() => {
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener(
      "scroll",
      _.debounce(() => {
        // console.log("scrolling stopped");
        onScroll();
      }, 50)
    );
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onScroll = () => {
    const topOffsets = {
      [TABS.HOME]: document.getElementById(TABS.HOME.toLowerCase()).offsetTop,
      [TABS.ABOUT]: document.getElementById(TABS.ABOUT.toLowerCase()).offsetTop,
      [TABS.CONTACT]: document.getElementById(TABS.CONTACT.toLowerCase())
        .offsetTop,
    };
    const pageYOffsetWithNav =
      window.pageYOffset + document.getElementById("nav").clientHeight;
    if (
      pageYOffsetWithNav >= topOffsets[TABS.HOME] &&
      pageYOffsetWithNav < topOffsets[TABS.ABOUT]
    )
      setActiveTab(TABS.HOME);
    else if (
      pageYOffsetWithNav >= topOffsets[TABS.ABOUT] &&
      pageYOffsetWithNav < topOffsets[TABS.CONTACT]
    )
      setActiveTab(TABS.ABOUT);
    else if (pageYOffsetWithNav >= topOffsets[TABS.CONTACT])
      setActiveTab(TABS.CONTACT);
    else setActiveTab(TABS.HOME);
  };

  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1224 });

  return (
    <div className={styles.navbar} id="nav">
      {renderLogo()}

      <div className={styles.linksContainer}>
        <NavLink
          to="/"
          onClick={() => scrollTo_(TABS.HOME)}
          style={TABS.HOME == selectTab ? { color: COLORS.PRIMARY } : null}>
          {isMobile ? (
            <HomeSvg
              stroke={
                TABS.HOME == selectTab ? COLORS.PRIMARY : COLORS.TEXT_COLOR
              }
            />
          ) : (
            "Home"
          )}
        </NavLink>

        <a
          style={TABS.ABOUT == selectTab ? { color: COLORS.PRIMARY } : null}
          onClick={() => scrollTo_(TABS.ABOUT)}>
          {isMobile ? (
            <AboutSvg
              stroke={
                TABS.ABOUT == selectTab ? COLORS.PRIMARY : COLORS.TEXT_COLOR
              }
            />
          ) : (
            "About us"
          )}
        </a>

        <a
          style={TABS.CONTACT == selectTab ? { color: COLORS.PRIMARY } : null}
          onClick={() => scrollTo_(TABS.CONTACT)}>
          {isMobile ? (
            <ContactSvg
              stroke={
                TABS.CONTACT == selectTab ? COLORS.PRIMARY : COLORS.TEXT_COLOR
              }
            />
          ) : (
            "Contact"
          )}
        </a>

        {!isMobile && (
          <img
            src={user}
            className={styles.userIcon}
            onClick={() => setIsOpen(!modalIsOpen)}
          />
        )}
      </div>

      <Login modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default NavBar;
