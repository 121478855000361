import { IContactData } from "models";
import { useEffect, useState } from "react";
import { MOCK_DATA } from "utils/mockData";
import styles from "./style.module.css";
import { Variants, useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import axios from "axios";
import { BASEURL } from "utils/baseurl";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

export const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const variantsForm: Variants = {
    visible: {
      opacity: 1,
      transition: { duration: 1.5 },
      translateX: 0,
    },
    hidden: { opacity: 0, translateX: window.innerWidth / 2 },
  };
  const [formRef, inView] = useInView();
  const control = useAnimation();
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    if (inView && !isShow) {
      setIsShow(true);
      control.start("visible");
    }
  }, [inView]);

  const contact_assess = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const data = {
      name,
      email,
      message,
    };
    try {
      setLoading(true);
      const res = await axios.post(BASEURL + `/contact-us`, data);
      console.log(res);
      setLoading(false);

      toast.success("Send successfully..", { position: "bottom-center" });
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error?.response?.status == 500) {
        toast.error(error?.response?.data?.message ?? "Something error .. ");
      } else {
        let err = Object.values(error.response?.data?.errors)[0] as string;
        toast.error(err ?? "Something error .. ");
      }
    }
  };

  return (
    <div id="contact" className={styles.container}>
      <motion.div
        ref={formRef}
        variants={variantsForm}
        initial={"hidden"}
        animate={control}
        className={styles.form}>
        <div className={styles.headLine}>Send message</div>
        <div className={styles.desc}>
          Feel free to contact us any time. We will get back to you as soon as
          we can!
        </div>

        <form onSubmit={contact_assess}>
          <div className={styles.inputRow}>
            <div className={styles.col}>
              <label className={styles.labelTxt}>
                Name <span className={styles.star}>*</span>
              </label>
              <input
                className={styles.input}
                type="text"
                onChange={(e: any) => setName(e.target.value)}
                placeholder="Your name"
                required
              />
            </div>

            <div className={styles.col}>
              <label className={styles.labelTxt}>
                Email <span className={styles.star}>*</span>
              </label>
              <input
                className={styles.input}
                onChange={(e: any) => setEmail(e.target.value)}
                type="email"
                placeholder="Your email"
                required
              />
            </div>
          </div>

          <div>
            <label className={styles.labelTxt}>
              Message <span className={styles.star}>*</span>
            </label>
            <input
              className={styles.largeInput}
              onChange={(e: any) => setMessage(e.target.value)}
              type="text"
              placeholder="Your message"
              required
            />
          </div>
          <i className="fa fa-circle-o-notch fa-spin" />
          <button type="submit" className={styles.btnCard} disabled={loading}>
            {loading ? (
              <CircularProgress sx={{ color: "#fff" }} size={20} />
            ) : (
              <>
                <div className={styles.btnTxt}>Send message</div>
                <img
                  className={styles.btnIcon}
                  src={require("assets/images/send.png")}
                />
              </>
            )}
          </button>
        </form>
      </motion.div>
      <div className={styles.flat}>
        {MOCK_DATA.contactData.map((item: IContactData, index) => {
          return (
            <a href={item?.link} target="_blank" key={index.toString()}>
              <motion.div
                className={styles.card}
                key={index.toString()}
                initial={{
                  opacity: 0,
                  translateY: 100,
                }}
                animate={
                  isShow && {
                    opacity: 1,
                    transition: { duration: 1, delay: index * 0.2 },
                    translateY: 0,
                  }
                }>
                <div className={styles.circle}>
                  <img className={styles.img} src={item?.image} />
                </div>
                <div className={styles.title}>{item?.title}</div>
              </motion.div>
            </a>
          );
        })}
      </div>
    </div>
  );
};
