import React, { useState } from "react";
import styles from "./style.module.css";
import { FormControlLabel, Checkbox, Modal } from "@mui/material";

interface Props {
  modalIsOpen: boolean;
  setIsOpen: (modalIsOpen: boolean) => void;
}

export const Login: React.FC<Props> = ({ setIsOpen, modalIsOpen }) => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [checked, setChecked] = useState(false);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setChecked(checked);
    console.log(checked);
  };

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      open={modalIsOpen}
      onClose={closeModal}
      disableAutoFocus={true}
      sx={{
        backgroundColor: "#00000050",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        display: "flex",
      }}
    >
      <div className={styles.container}>
        <img
          src={require("assets/images/logo-without-text.png")}
          alt="Brand Logo"
          title="assess"
          className={styles.logo}
        />
        <div className={styles.title}>
          Lorem ipsum dolor sit amet, consectetur.
        </div>

        <form className={styles.form} onSubmit={() => {}}>
          <div className={styles.col}>
            <label className={styles.labelTxt}>
              Email <span className={styles.star}>*</span>
            </label>
            <input
              className={styles.input}
              type="text"
              onChange={(e: any) => setEmail(e.target.value)}
              placeholder="Your email"
              required
            />
          </div>

          <div className={styles.col}>
            <label className={styles.labelTxt}>
              Password <span className={styles.star}>*</span>
            </label>
            <input
              className={styles.input}
              onChange={(e: any) => setEmail(e.target.value)}
              type="Password"
              placeholder="Your password"
              required
            />
          </div>

          <div className={styles.row}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={checked}
                  onChange={handleChange}
                  style={{
                    color: checked ? "blue" : "gray",
                  }}
                  inputProps={{ "aria-label": "Checkbox A" }}
                />
              }
              label={<p className={styles.remember}>Remember me</p>}
            />
            <div className={styles.forget}>Forgot Password?</div>
          </div>
          <button type="submit" className={styles.btnCard} disabled={false}>
            <div className={styles.btnTxt}>Login</div>
          </button>
        </form>
      </div>
    </Modal>
  );
};
