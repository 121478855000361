import { Contact, Footer, Home } from "components";
import { About } from "components/about";

import CSS from "csstype";
import { useEffect, useRef, useState } from "react";
export const LandingPageScreen = () => {
  const [isMounted, setIsMounted] = useState(false);

  return (
    <div style={container}>
      <Home setIsMounted={setIsMounted} />
      {true && (
        <>
          <About />
          <Contact />
          <Footer />
        </>
      )}
    </div>
  );
};

const container: CSS.Properties = {
  display: "flex",
  flex: 1,
  // background: "yellow",
  flexDirection: "column",
};
