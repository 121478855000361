import { SocialLinks } from "components/socialLinks";
import styles from "./style.module.css";
import { Desktop, Mobile, Tablet } from "../../utils/screen-sizes";

export const Footer = () => {
  return (
    <div id="footer">
      <Desktop>
        <div className={styles.container}>
          <SocialLinks />
          <img
            src={require("assets/images/logo-white.png")}
            alt="Brand Logo"
            title="assess"
            className={styles.logo}
          />
          <div className={styles.copy}>
            Copyright © 2022. All Right Reserved.
          </div>
        </div>
      </Desktop>

      <Tablet>
        <div className={styles.container}>
          <img
            src={require("assets/images/logo-white.png")}
            alt="Brand Logo"
            title="assess"
            className={styles.logo}
          />
          <div className={styles.col}>
            <SocialLinks />
            <div className={styles.copy}>
              Copyright © 2022. All Right Reserved.
            </div>
          </div>
        </div>
      </Tablet>

      <Mobile>
        <div className={styles.container}>
          <img
            src={require("assets/images/logo-white.png")}
            alt="Brand Logo"
            title="assess"
            className={styles.logo}
          />
          <div className={styles.col}>
            <SocialLinks />
            <div className={styles.copy}>
              Copyright © 2022. All Right Reserved.
            </div>
          </div>
        </div>
      </Mobile>
    </div>
  );
};
